import { Visibility } from "@mui/icons-material";
import {
  IconButton,
  TableCell,
  TableRow,
  Tooltip,
  useTheme,
} from "@mui/material";
import moment from "moment";
import { useState } from "react";
import { Link } from "react-router-dom";
import { HttpClient } from "src/Helpers/http/http";
import { url } from "src/Helpers/utils/Constant";
import Toaster from "src/Toaster/Toaster";
import DeleteConfirmation from "src/Utils/DeleteConfirmation";
import { ITrainingInvoiceList } from "src/Utils/type";
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";

type IProps = {
  trainingInvoiceList: ITrainingInvoiceList;
  index: number;
  allInvoices: ITrainingInvoiceList[];
  setAllInvoices: any;
};

function TrainingInvoiceTableRow({
  trainingInvoiceList,
  index,
  allInvoices,
  setAllInvoices,
}: IProps) {
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const handleDelete = (id: number) => {
    (async function () {
      try {
        setIsLoading(true);
        const uri = `${url}/api/admin/training/invoice/${id}`;
        const data = await HttpClient.delete(uri);

        if (data) {
          const filterData = allInvoices.filter(
            (item) => item.training_payment_invoice_id !== id
          );

          setAllInvoices(filterData);

          Toaster().fire({
            icon: "success",
            title: "Deleted successfully!",
          });

          setIsLoading(false);
          setOpen(false);
        }
      } catch (error) {
        setIsLoading(false);
        Toaster().fire({
          icon: "error",
          title: `${error.response?.data?.message}`,
        });
      }
    })();
  };
  return (
    <TableRow hover>
      <TableCell>{index + 1}</TableCell>

      <TableCell>
        {moment(trainingInvoiceList.training_payment_invoice_issue_date).format(
          "DD-MMM-YYYY"
        )}
      </TableCell>
      <TableCell>{trainingInvoiceList.invoice_no}</TableCell>
      <TableCell>{trainingInvoiceList.user_training_trainee_name}</TableCell>
      <TableCell style={{ color: "green" }}>
        {trainingInvoiceList.training_payment_invoice_grand_total}
      </TableCell>
      <TableCell style={{ color: "red" }}>
        {trainingInvoiceList.training_payment_invoice_due}
      </TableCell>
      <TableCell>
        <Tooltip title="View" arrow>
          <Link
            to={`/training-invoice/details/${trainingInvoiceList.training_payment_invoice_id}`}
          >
            <IconButton
              sx={{
                "&:hover": {
                  background: theme.colors.primary.lighter,
                },
                color: theme.palette.primary.main,
              }}
              color="inherit"
              size="small"
            >
              <Visibility fontSize="small" />
            </IconButton>
          </Link>
        </Tooltip>
        <Tooltip title="Delete invoice" arrow>
          <IconButton
            onClick={() => setOpen(true)}
            sx={{
              "&:hover": { background: theme.colors.error.lighter },
              color: theme.palette.error.main,
            }}
            color="inherit"
            size="small"
          >
            <DeleteTwoToneIcon fontSize="small" />
          </IconButton>
        </Tooltip>
        {/* Delele Confirmation modal  */}
        <DeleteConfirmation
          open={open}
          handleClose={() => setOpen(false)}
          text="Want to delete this invoice?"
          handleDeleteFun={() =>
            handleDelete(trainingInvoiceList?.training_payment_invoice_id)
          }
          loading={isLoading}
        />
      </TableCell>
    </TableRow>
  );
}

export default TrainingInvoiceTableRow;
