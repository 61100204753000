import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useAuthContext } from "src/components/Context/AuthContext/AuthContext";

import { url } from "src/Helpers/utils/Constant";
import {
  IpaymentableItem,
  IuserMemberList,
} from "src/Types/module/invoice/invoiceTypes";
import { debounce } from "lodash";
import { Controller, useFieldArray, useForm, useWatch } from "react-hook-form";
import { HttpClient } from "src/Helpers/http/http";
import Toaster from "src/Toaster/Toaster";
import { LoadingButton } from "@mui/lab";
import { IAccountType } from "../../../MemberAccount/Account/types/AccountTypes";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import DynamicInvoiceField from "./DynamicInvoiceField";
interface ISingleMember {
  user_member_id: number;
  user_member_company_name: string;
  user_member_representative_name: string;
}
const CreateInvoice = ({ handleClose, setRefresh }) => {
  const { user } = useAuthContext();
  const [allPaymentableItem, setAllPaymentableItem] = useState<
    IpaymentableItem[]
  >([]);
  const [userMember, setUserMember] = useState<IuserMemberList[]>([]);
  const [member, setMember] = useState<ISingleMember | null>(null);
  const [_isLoading, setIsLoading] = useState<Boolean>(false);
  const [companyName, seTCompanyName] = useState<string>("");
  const { register, handleSubmit, control, watch, setValue } = useForm();
  const { fields, append, remove } = useFieldArray({
    control,
    name: "items",
  });
  const withMoneyReceipt = watch("with_mr");
  const navigate = useNavigate();
  const [accountData, setAccountData] = useState<IAccountType[]>([]);
  const [loading, setLoading] = useState(false);
  const paymentMethod = watch("payment_method");
  const [subscription, setSubscription] = useState(0);
  const items = watch("items");
  console.log(items);

  const totalItemAmount = items?.reduce(
    (sum, item) => sum + Number(item.amount || 0),
    0
  );

  if (fields.length === 0) {
    append({ id: "", amount: "", year: "" });
  }
  useEffect(() => {
    setIsLoading(true);
    try {
      if (user?.user_admin_id) {
        setIsLoading(true);
        const urls = [
          `${url}/api/atab/payment/get/all/paymentable/item`,
          `${url}/api/atab/user-member/company-name/get-all/status-or-all/all?company_name=${companyName}`,
        ];
        Promise.all(
          urls.map((url) => fetch(url).then((response) => response.json()))
        ).then((data) => {
          if (data?.length) {
            setAllPaymentableItem(data[0].data);
            setUserMember(data[1].data);
            setIsLoading(false);
          } else {
            setIsLoading(false);
          }
        });
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  }, [user.user_admin_id, companyName]);

  useEffect(() => {
    setIsLoading(true);
    (async function () {
      try {
        HttpClient.get(`${url}/api/atab/common-account?CompanyProfileId=1`)
          .then(function (res: any) {
            if (res.success) {
              setAccountData(res.data);
              setIsLoading(false);
            } else {
              setIsLoading(false);
            }
          })
          .catch(function (err) {
            setAccountData([]);
            setIsLoading(false);
          });
      } catch (error) {
        setIsLoading(false);
      }
    })();
  }, []);

  const onSubmit = async (submitData: any) => {
    setLoading(true);
    submitData.items.forEach((item) => {
      item.amount = Number(item.amount);
      if (item.year.length) {
        item.year = item.year.map((year) => year.value).join("-");
      } else {
        item.year = `${moment().year()}`;
      }
    });

    try {
      const res: any = await HttpClient.post(
        `${url}/api/atab/admin/invoice`,
        submitData
      );
      if (res.success) {
        Toaster().fire({
          icon: "success",
          title: res.message,
        });
        setLoading(false);
        handleClose();
        setRefresh((prv) => !prv);
        setSubscription(0);
        if (res?.data?.direct_mr) {
          navigate(`/money-receipt/list/${res.data.id}`);
        } else {
          navigate(`/invoices/transactions/${res.data.id}`);
        }
      } else {
        setLoading(false);
      }
    } catch (err: any) {
      Toaster().fire({
        icon: "error",
        title: `${err.response?.data?.message}`,
      });
      setLoading(false);
    }
  };
  const handelInputChange = debounce((value: string) => {
    seTCompanyName(value);
  }, 500);
  return (
    <>
      <Helmet>
        <title>Atab - Create invoice</title>
      </Helmet>

      <Box
        sx={{
          mx: 4,
          mt: 2,
          maxWidth: "800px",
        }}
        component="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md={12} lg={6}>
            <Controller
              control={control}
              name={`member_id`}
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  value={member || null}
                  onChange={(_event, newValue) => {
                    field.onChange(newValue ? newValue.user_member_id : null);
                    setMember(newValue);
                  }}
                  isOptionEqualToValue={(option, value) =>
                    option.user_member_id === value.user_member_id
                  }
                  loading={true}
                  id="controllable-states-demo"
                  options={userMember}
                  onInputChange={(_event, newInputValue) => {
                    handelInputChange(newInputValue);
                  }}
                  getOptionLabel={(option) => option.user_member_company_name}
                  renderTags={(value: readonly any[], getTagProps) =>
                    value.map((option: any, index: number) => (
                      <Chip
                        variant="filled"
                        label={option}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select member"
                      required
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: <>{params.InputProps.endAdornment}</>,
                      }}
                    />
                  )}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={12} lg={6}>
            <TextField
              fullWidth
              required
              id="invoice_date"
              label="Invoice Date"
              type="date"
              defaultValue={moment().format("YYYY-MM-DD")}
              InputLabelProps={{ shrink: true }}
              variant="outlined"
              {...register("invoice_date")}
            />
          </Grid>
          <Grid item xs={12}>
            {/* Expense Items */}
            {fields.map((item, index) => {
              return (
                <DynamicInvoiceField
                  register={register}
                  allPaymentableItem={allPaymentableItem}
                  item={item}
                  index={index}
                  key={index}
                  control={control}
                  remove={remove}
                  fields={fields}
                  setValue={setValue}
                />
              );
              // const find = allPaymentableItem?.find(
              //   (item) =>
              //     item?.atab_paymentable_item_id === Number(subscription)
              // );
              // const it = `items.${index}.id`;
              // return (
              //   <Grid
              //     container
              //     spacing={2}
              //     key={item.id}
              //     alignItems="center"
              //     sx={{ marginBottom: "14px" }}
              //   >
              //     <Grid item xs={12} md={12} lg={4}>
              //       <FormControl
              //         sx={{ mt: 1 }}
              //         fullWidth
              //         required
              //         margin="normal"
              //       >
              //         <InputLabel id="demo-simple-select-label">
              //           Select Item
              //         </InputLabel>
              //         <Select
              //           labelId="demo-simple-select-label"
              //           id="demo-simple-select"
              //           {...register(`items.${index}.id`)}
              //           label="Expense Head"
              //           // onChange={(e) => handelFond(e.target.value, index)}
              //         >
              //           {allPaymentableItem?.map((item) => {
              //             return (
              //               <MenuItem
              //                 key={item.atab_paymentable_item_id}
              //                 value={item.atab_paymentable_item_id}
              //               >
              //                 {item.atab_paymentable_item_name} (
              //                 {item.atab_paymentable_item_bdt_amount} bdt)
              //               </MenuItem>
              //             );
              //           })}
              //         </Select>
              //       </FormControl>
              //     </Grid>
              //     <Grid item xs={12} md={12} lg={2}>
              //       <TextField
              //         fullWidth
              //         required
              //         // defaultValue={0}
              //         label="Amount"
              //         type="number"
              //         variant="outlined"
              //         {...register(`items.${index}.amount`)}
              //         InputLabelProps={{
              //           shrink: true,
              //         }}
              //       />
              //     </Grid>
              //     {/* {item.atab_paymentable_item_id == 2 && ( */}

              //     {Number(it) === 2 ? (
              //       <Grid item xs={12} md={12} lg={5}>
              //         <Controller
              //           control={control}
              //           name={`items.${index}.year`}
              //           render={({ field }) => (
              //             <Autocomplete
              //               multiple
              //               options={Array.from({ length: 100 }, (_, i) => {
              //                 const year = 2000 + i;
              //                 return { label: `${year}`, value: `${year}` };
              //               })}
              //               getOptionLabel={(option) => option.label}
              //               value={field.value || []}
              //               onChange={(e, newValue) => {
              //                 const uniqueValues = Array.from(
              //                   new Set(newValue.map((item) => item.value))
              //                 ).map((value) =>
              //                   newValue.find((item) => item.value === value)
              //                 );

              //                 field.onChange(uniqueValues);
              //               }}
              //               renderInput={(params) => (
              //                 <TextField
              //                   {...params}
              //                   variant="outlined"
              //                   label="Select years"
              //                   placeholder="More..."
              //                 />
              //               )}
              //             />
              //           )}
              //         />
              //       </Grid>
              //     ) : (
              //       ""
              //     )}

              //     {/* )} */}
              //     {fields.length > 1 && (
              //       <Grid item xs={1}>
              //         <IconButton onClick={() => remove(index)} color="error">
              //           <DeleteIcon />
              //         </IconButton>
              //       </Grid>
              //     )}
              //   </Grid>
              // );
            })}
          </Grid>
          <Grid item xs={12}>
            <Button
              type="button"
              variant="contained"
              onClick={() => append({ id: "", amount: "", year: "" })}
            >
              Add Item
            </Button>
            <FormControlLabel
              style={{ marginLeft: "30px" }}
              control={<Checkbox {...register("with_mr")} />}
              label="With Money Receipt"
            />
          </Grid>

          {withMoneyReceipt && (
            <>
              <Grid
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                item
                xs={12}
                md={12}
                lg={6}
              >
                <TextField
                  fullWidth
                  select
                  required
                  sx={{ mt: 1 }}
                  id="payment_method"
                  label="Payment Method"
                  variant="outlined"
                  {...register("payment_method")}
                >
                  {["Mobile Banking", "Cash", "Bank", "Cheque"].map(
                    (method) => (
                      <MenuItem key={method} value={method}>
                        {method}
                      </MenuItem>
                    )
                  )}
                </TextField>
              </Grid>
              <Grid item xs={12} md={12} lg={6}>
                <FormControl fullWidth required margin="normal">
                  <InputLabel id="demo-simple-select-label">Account</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    {...register("account_id")}
                    label="Account"
                  >
                    {accountData?.map((account) => {
                      return (
                        <MenuItem key={account.id} value={account.id}>
                          {account.account_name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
            </>
          )}
          {paymentMethod === "Cheque" && withMoneyReceipt && (
            <>
              <Grid item xs={12} md={12} lg={6}>
                <TextField
                  fullWidth
                  required
                  id="BankName"
                  label="Bank Name"
                  type="text"
                  variant="outlined"
                  {...register("BankName")}
                />
              </Grid>
              <Grid item xs={12} md={12} lg={6}>
                <TextField
                  fullWidth
                  required
                  id="chequeNo"
                  label="Cheque Number"
                  type="text"
                  variant="outlined"
                  {...register("chequeNo")}
                />
              </Grid>

              <Grid item xs={12} md={12} lg={6}>
                <TextField
                  fullWidth
                  id="ChequeDate"
                  label="Cheque Date"
                  type="date"
                  required
                  defaultValue={moment().format("YYYY-MM-DD")}
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                  {...register("ChequeDate")}
                />
              </Grid>
            </>
          )}
        </Grid>

        <Grid sx={{ mt: 2 }} item xs={12} md={12} lg={12}>
          <TextField
            fullWidth
            required
            rows={3}
            multiline
            id="remark"
            label="Remark"
            type="text"
            variant="outlined"
            {...register("remark")}
          />
        </Grid>
        <p style={{ fontSize: "14px", fontWeight: "bold" }}>
          Total Invoice Amount : {totalItemAmount}
        </p>
        <Box textAlign={"center"} mt={3}>
          {loading ? (
            <LoadingButton
              loading={loading}
              fullWidth
              color="secondary"
              disableElevation
              sx={{
                mt: 3,
                mb: 2,
                py: 1,
              }}
              variant="contained"
            >
              Loading...
            </LoadingButton>
          ) : (
            <Button
              type="submit"
              variant="contained"
              disableElevation
              sx={{
                mt: 3,
                mb: 2,
                py: 1,
              }}
            >
              Create
            </Button>
          )}
        </Box>
      </Box>
    </>
  );
};

export default CreateInvoice;
