import { Dispatch, SetStateAction, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { IMemberInfo } from "src/Types/module/member/memberTypes";
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import Toaster from "src/Toaster/Toaster";
import { useParams } from "react-router-dom";
import { HttpClient } from "src/Helpers/http/http";
import { useAuthContext } from "src/components/Context/AuthContext/AuthContext";
import axios from "axios";
import { url } from "src/Helpers/utils/Constant";
import Autocomplete from "@mui/material/Autocomplete";

type IProps = {
  memberInfo: IMemberInfo;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  setRefresh: any;
};

const UpdateBasicInfo = ({ memberInfo, setShowModal, setRefresh }: IProps) => {
  const { user } = useAuthContext();
  const Toast = Toaster();
  const [memberData, setMemberData] = useState<any>({});
  const { memberId } = useParams();
  const [loading, setLoading] = useState(false);

  const [adminList, setAdminList] = useState([]);

  useEffect(() => {
    (async function () {
      try {
        axios
          .get(`${url}/api/atab/admin/get/all/status/active`)
          .then(function (res) {
            if (res.data.success) {
              setAdminList(res?.data?.data);
              setLoading(false);
            } else {
              setLoading(false);
            }
          })
          .catch(function (err) {
            setAdminList([]);
          });
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  const handleMemberUpdate = async () => {
    setLoading(true);

    const submitData: any = {};

    for (const key in memberData) {
      if (memberData[key]) {
        if (key === "user_member_account_status") {
          if (memberData[key] === "checker-reject") {
            submitData[key] = "pending";
          } else {
            submitData[key] = memberData[key];
          }
        } else {
          submitData[key] = memberData[key];
        }
      }
    }

    try {
      const res: any = await HttpClient.put(
        `/api/atab/user-member/admin/update/member/${memberId}`,
        submitData
      );

      if (res.success) {
        Toast.fire({
          icon: "success",
          title: "Member successfully updated",
        });
        setShowModal(false);
        setRefresh((pre) => !pre);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (err) {
      Toast.fire({
        icon: "error",
        title: `Update Failed ! \n ${err?.response?.data?.message}`,
      });
      setLoading(false);
      // setShowModal(false);
    }
  };

  const applicationChecker = adminList
    ?.filter((admin) => admin.user_admin_role_name === "Application Inspection")
    .map((admin) => {
      return {
        label: admin.user_admin_name,
        id: admin.user_admin_id,
      };
    });

  return (
    <Box p={2}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <FormControl fullWidth>
            <InputLabel required id="demo-simple-select-label">
              Status
            </InputLabel>

            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Status"
              required
              defaultValue={memberInfo?.user_member_account_status || ""}
              onChange={(e: any) =>
                setMemberData({
                  ...memberData,
                  user_member_account_status: e.target.value,
                })
              }
            >
              {memberInfo?.user_member_account_status !==
                "Inspection Completed" && (
                <MenuItem key="pending" value="pending">
                  Pending
                </MenuItem>
              )}
              {memberInfo?.user_member_account_status !== "pending" &&
              memberInfo?.user_member_account_status !==
                "Application Inspection" &&
              (memberInfo?.user_member_account_status === "InProgress" ||
                memberInfo?.user_member_account_status !==
                  "Inspection Completed" ||
                !memberInfo?.inspection_report_id) ? (
                <MenuItem key="active" value="active">
                  Active
                </MenuItem>
              ) : null}

              {memberInfo?.user_member_account_status ===
                "Inspection Completed" && (
                <MenuItem
                  key="Inspection Completed"
                  value="Inspection Completed"
                  disabled
                >
                  Inspection Completed
                </MenuItem>
              )}

              {!memberInfo?.inspection_report_id &&
                memberInfo?.user_member_account_status !== "active" && (
                  <MenuItem
                    key="application_inspection"
                    value="Application Inspection"
                  >
                    Forward for office inspection
                  </MenuItem>
                )}

              {memberInfo?.user_member_account_status !== "pending" &&
                memberInfo?.user_member_account_status !== "active" &&
                memberInfo?.user_member_account_status !==
                  "Application Inspection" && (
                  <MenuItem key="InProgress" value="InProgress">
                    InProgress
                  </MenuItem>
                )}

              <MenuItem key="rejected" value="rejected">
                Rejected
              </MenuItem>
              <MenuItem key="blocked" value="blocked">
                Black list
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
        {memberData?.user_member_account_status ===
          "Application Inspection" && (
          <Grid item xs={12} md={4}>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={applicationChecker}
              isOptionEqualToValue={(applicationChecker, value) =>
                applicationChecker.id === value.id
              }
              onChange={(_e, newValue) =>
                setMemberData({
                  ...memberData,
                  application_forwarder: newValue?.id,
                })
              }
              renderInput={(params) => (
                <TextField {...params} label="Select admin" required />
              )}
            />
          </Grid>
        )}

        <Grid item xs={12} md={4}>
          <TextField
            id="outlined-textarea"
            label="Company name"
            placeholder="Update company name"
            defaultValue={memberInfo.user_member_company_name || ""}
            onChange={(e: any) =>
              setMemberData({
                ...memberData,
                user_member_company_name: e.target.value,
              })
            }
            fullWidth
            type="text"
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <TextField
            id="outlined-textarea"
            label="Email"
            placeholder="Enter email address"
            defaultValue={memberInfo.user_member_email || ""}
            onChange={(e: any) =>
              setMemberData({
                ...memberData,
                user_member_email: e.target.value,
              })
            }
            fullWidth
            type="email"
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <TextField
            id="outlined-textarea"
            label="Phone"
            placeholder="Enter phone number"
            defaultValue={memberInfo.user_member_phone || ""}
            onChange={(e: any) =>
              setMemberData({
                ...memberData,
                user_member_phone: e.target.value,
              })
            }
            multiline
            fullWidth
            type="text"
          />
        </Grid>

        {/* fnanfna */}

        {user?.user_admin_role === "Super Admin" &&
        memberData.user_member_account_status === "active" ? (
          <Grid item xs={12} md={4}>
            <TextField
              id="outlined-textarea"
              label="Approved date "
              InputLabelProps={{
                shrink: true,
              }}
              // defaultValue={memberInfo.user_member_id_card_number || ''}
              onChange={(e: any) =>
                setMemberData({
                  ...memberData,
                  user_member_active_date: e.target.value,
                })
              }
              fullWidth
              type="date"
            />
          </Grid>
        ) : (
          ""
        )}
        {!memberInfo.user_member_membership_payment_date && (
          <Grid item xs={12} md={4}>
            <TextField
              id="outlined-textarea"
              label="Membership payment date"
              InputLabelProps={{
                shrink: true,
              }}
              // defaultValue={memberInfo.user_member_id_card_number || ''}
              onChange={(e: any) =>
                setMemberData({
                  ...memberData,
                  user_member_membership_payment_date: e.target.value,
                })
              }
              fullWidth
              type="date"
            />
          </Grid>
        )}

        {!memberInfo.user_member_membership_payment_expiry_date && (
          <Grid item xs={12} md={4}>
            <TextField
              id="outlined-textarea"
              label="Membership payment expiry date"
              InputLabelProps={{
                shrink: true,
              }}
              // defaultValue={memberInfo.user_member_id_card_number || ''}
              onChange={(e: any) =>
                setMemberData({
                  ...memberData,
                  user_member_membership_payment_expiry_date: e.target.value,
                })
              }
              fullWidth
              type="date"
            />
          </Grid>
        )}

        <Grid item xs={12} md={4}>
          <TextField
            id="outlined-textarea"
            label="ID card issue date"
            InputLabelProps={{
              shrink: true,
            }}
            // defaultValue={memberInfo.user_member_id_card_number || ''}
            onChange={(e: any) =>
              setMemberData({
                ...memberData,
                user_member_id_card_issue_date: e.target.value,
              })
            }
            fullWidth
            type="date"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            id="outlined-textarea"
            label="ID card expiry date"
            InputLabelProps={{
              shrink: true,
            }}
            // defaultValue={memberInfo.user_member_id_card_number || ''}
            onChange={(e: any) =>
              setMemberData({
                ...memberData,
                user_member_id_card_expiry_date: e.target.value,
              })
            }
            fullWidth
            type="date"
          />
        </Grid>

        {memberData.user_member_account_status === "rejected" ||
        memberData.user_member_account_status === "blocked" ? (
          <Grid item xs={12} md={8}>
            <TextField
              rows={4}
              id="outlined-textarea"
              label="Reject reason"
              placeholder="Reject reason"
              defaultValue={memberInfo.user_member_reject_reason || ""}
              onChange={(e: any) =>
                setMemberData({
                  ...memberData,
                  user_member_reject_reason: e.target.value,
                })
              }
              multiline
              fullWidth
              type="text"
            />
          </Grid>
        ) : (
          ""
        )}
        {memberData.user_member_account_status === "checker-reject" ? (
          <Grid item xs={12} md={8}>
            <TextField
              rows={4}
              id="outlined-textarea"
              label="Reject reason"
              placeholder="Reject reason"
              defaultValue={memberInfo.checker_reject_reason || ""}
              onChange={(e: any) =>
                setMemberData({
                  ...memberData,
                  checker_reject_reason: e.target.value,
                })
              }
              multiline
              fullWidth
              type="text"
            />
          </Grid>
        ) : (
          ""
        )}
      </Grid>

      <Box mt={3} textAlign="right">
        {loading ? (
          <Button
            disabled
            variant="contained"
            disableElevation
            className="add-remove-button"
          >
            loading..
          </Button>
        ) : (
          <Button onClick={() => handleMemberUpdate()} variant="contained">
            Update
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default UpdateBasicInfo;
