import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Container,
  debounce,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { HttpClient } from "src/Helpers/http/http";
import { TableLoader, TableNotFound } from "src/Utils/TableLoader";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import { IMemberLedger } from "./memberLedgerReportTypes";
import MemberLedgerReportTableRow from "./MemberLedgerReportTableRow";
import { IuserMemberList } from "src/Types/module/invoice/invoiceTypes";

const SingleMemberLedgerReport = () => {
  const [MemberLedgerData, setMemberLedgerData] = useState<IMemberLedger[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [singleMember, setSingleMember] = useState<IuserMemberList | null>(
    null
  );
  const [userMember, setUserMember] = useState<IuserMemberList[]>([]);
  const [companyName, seTCompanyName] = useState<string>("");
  const [startDate, setStartDate] = useState<Dayjs | string>(
    dayjs().startOf("month")
  );
  const [endDate, setEndDate] = useState<Dayjs | string>(
    dayjs().endOf("month")
  );
  const handleFetchReport = async () => {
    setLoading(true);
    try {
      let uri = `/api/atab/admin/member-ledger?from_date=${dayjs(startDate).format("YYYY-MM-DD")}&to_date=${dayjs(endDate).format("YYYY-MM-DD")}`;
      if (singleMember?.user_member_id) {
        uri += `&member_id=${singleMember?.user_member_id}`;
      }
      const res: any = await HttpClient.get(uri);
      if (res.success) {
        setMemberLedgerData(res.data);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };
  useEffect(() => {
    (async function () {
      try {
        let uri = `/api/atab/user-member/company-name/get-all/status-or-all/all?company_name=${companyName}`;
        const res: any = await HttpClient.get(uri);
        if (res.success) {
          setUserMember(res.data);
        } else {
        }
      } catch (error) {}
    })();
  }, [companyName]);

  const handelInputChange = debounce((value: string) => {
    seTCompanyName(value);
  }, 500);
  return (
    <Container sx={{ mt: 4 }} maxWidth="xl">
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
        spacing={3}
      >
        <Grid item xs={12}>
          <Card>
            <CardHeader
              action={
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    gap: 1,
                  }}
                >
                  <Grid
                    item
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 1,
                    }}
                  >
                    <DatePicker
                      label="Start Date"
                      value={startDate}
                      onChange={(e: any) => setStartDate(e)}
                    />
                  </Grid>
                  <Grid
                    item
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 1,
                    }}
                  >
                    <Typography
                      fontWeight={"bold"}
                      variant="h6"
                      component="h6"
                      gutterBottom
                    >
                      To
                    </Typography>

                    <DatePicker
                      label="End Date"
                      value={endDate}
                      onChange={(e: any) => setEndDate(e)}
                    />
                  </Grid>
                  <Grid item style={{ width: "240px" }}>
                    <Autocomplete
                      value={singleMember}
                      onChange={(event: any, newValue: any) => {
                        setSingleMember(newValue);
                      }}
                      id="controllable-states-demo"
                      options={userMember}
                      onInputChange={(_event, newInputValue) => {
                        handelInputChange(newInputValue);
                      }}
                      getOptionLabel={(option) =>
                        option.user_member_company_name
                      }
                      renderTags={(value: readonly any[], getTagProps) =>
                        value.map((option: any, index: number) => (
                          <Chip
                            variant="filled"
                            label={option}
                            {...getTagProps({ index })}
                          />
                        ))
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select member"
                          placeholder="Select Member"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item>
                    <Button
                      size="large"
                      variant="contained"
                      onClick={handleFetchReport}
                      disabled={loading}
                    >
                      {loading ? "Loading..." : "🔍 Search"}
                    </Button>
                  </Grid>
                </Box>
              }
              title="Member Ledger"
            />
            <Divider />
            <CardContent>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Voucher No</TableCell>
                      <TableCell>Date</TableCell>
                      <TableCell>Particular</TableCell>
                      <TableCell>Details</TableCell>
                      <TableCell>Type</TableCell>
                      <TableCell>Method</TableCell>
                      <TableCell>Debit</TableCell>
                      <TableCell>Credit</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {loading ? (
                      <TableLoader />
                    ) : MemberLedgerData.length ? (
                      <>
                        {MemberLedgerData.map((item) => (
                          <MemberLedgerReportTableRow
                            key={item.id}
                            singleMemberLedgerData={item}
                          />
                        ))}
                      </>
                    ) : (
                      <TableNotFound />
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default SingleMemberLedgerReport;
