import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { HttpClient } from "src/Helpers/http/http";
import { TableLoader, TableNotFound } from "src/Utils/TableLoader";
import { ITrialBalanceReportType } from "./trialBalanceReportTypes";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import TrialBalanceReportTableRow from "./TrialBalanceReportTableRow";

const TraineeTrialBalanceReport = () => {
  const [TrialBalanceData, setTrialBalanceData] = useState<
    ITrialBalanceReportType[]
  >([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<Dayjs | string>(
    dayjs().startOf("month")
  );
  const [endDate, setEndDate] = useState<Dayjs | string>(
    dayjs().endOf("month")
  );
  const handleFetchReport = async () => {
    setLoading(true);
    try {
      let uri = `/api/admin/accounts/report/trial-balance?CompanyProfileId=2&from_date=${dayjs(startDate).format("YYYY-MM-DD")}&to_date=${dayjs(endDate).format("YYYY-MM-DD")}`;
      const res: any = await HttpClient.get(uri);
      if (res.success) {
        setTrialBalanceData(res.data);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };
  /* total Debit */
  const totalDebit = TrialBalanceData?.reduce(
    (sum, item) => sum + Number(item.TotalDebit),
    0
  );
  /* total Credit */
  const totalCredit = TrialBalanceData?.reduce(
    (sum, item) => sum + Number(item.TotalCredit),
    0
  );
  return (
    <Container sx={{ mt: 4 }} maxWidth="xl">
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
        spacing={3}
      >
        <Grid item xs={12}>
          <Card>
            <CardHeader
              action={
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    gap: 1,
                  }}
                >
                  <Grid
                    item
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 1,
                    }}
                  >
                    <DatePicker
                      label="Start Date"
                      value={startDate}
                      onChange={(e: any) => setStartDate(e)}
                    />
                  </Grid>
                  <Grid
                    item
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 1,
                    }}
                  >
                    <Typography
                      fontWeight={"bold"}
                      variant="h6"
                      component="h6"
                      gutterBottom
                    >
                      To
                    </Typography>

                    <DatePicker
                      label="End Date"
                      value={endDate}
                      onChange={(e: any) => setEndDate(e)}
                    />
                  </Grid>
                  <Grid item>
                    <Button
                      size="large"
                      variant="contained"
                      onClick={handleFetchReport}
                      disabled={loading}
                    >
                      {loading ? "Loading..." : "🔍 Search"}
                    </Button>
                  </Grid>
                </Box>
              }
              title="Trainee Trial Balance"
            />
            <Divider />
            <CardContent>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <div>
                            <span>Particulars</span>
                          </div>
                          <div
                            style={{
                              width: "30%",
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <span>Debit</span>
                            <span>Credit</span>
                          </div>
                        </div>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {loading ? (
                      <TableLoader />
                    ) : TrialBalanceData.length ? (
                      <>
                        {TrialBalanceData.map((item) => (
                          <TrialBalanceReportTableRow
                            key={item.Id}
                            singleTrialBalanceData={item}
                          />
                        ))}
                      </>
                    ) : (
                      <TableNotFound />
                    )}
                    <TableRow>
                      <TableCell>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <div>
                            <strong>Balance</strong>
                          </div>
                          <div
                            style={{
                              width: "30%",
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <strong>{totalDebit}</strong>
                            <strong>{totalCredit}</strong>
                          </div>
                        </div>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default TraineeTrialBalanceReport;
