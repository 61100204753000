import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { HttpClient } from "src/Helpers/http/http";
import { TableLoader, TableNotFound } from "src/Utils/TableLoader";
import JournalReportTableRow from "./JournalReportTableRow";
import { IJournalReportType } from "./journalReportTypes";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";

const TraineeJournalReport = () => {
  const [journalData, setJournalData] = useState<IJournalReportType[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<Dayjs | string>(
    dayjs().startOf("month")
  );
  const [endDate, setEndDate] = useState<Dayjs | string>(
    dayjs().endOf("month")
  );
  const handleFetchReport = async () => {
    setLoading(true);
    try {
      let uri = `/api/admin/accounts/report/journal?CompanyProfileId=2&from_date=${dayjs(startDate).format("YYYY-MM-DD")}&to_date=${dayjs(endDate).format("YYYY-MM-DD")}`;
      const res: any = await HttpClient.get(uri);
      if (res.success) {
        setJournalData(res.data);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };
  return (
    <Container sx={{ mt: 4 }} maxWidth="xl">
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
        spacing={3}
      >
        <Grid item xs={12}>
          <Card>
            <CardHeader
              action={
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    gap: 1,
                  }}
                >
                  <Grid
                    item
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 1,
                    }}
                  >
                    <DatePicker
                      label="Start Date"
                      value={startDate}
                      onChange={(e: any) => setStartDate(e)}
                    />
                  </Grid>
                  <Grid
                    item
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 1,
                    }}
                  >
                    <Typography
                      fontWeight={"bold"}
                      variant="h6"
                      component="h6"
                      gutterBottom
                    >
                      To
                    </Typography>

                    <DatePicker
                      label="End Date"
                      value={endDate}
                      onChange={(e: any) => setEndDate(e)}
                    />
                  </Grid>
                  <Grid item>
                    <Button
                      size="large"
                      variant="contained"
                      onClick={handleFetchReport}
                      disabled={loading}
                    >
                      {loading ? "Loading..." : "🔍 Search"}
                    </Button>
                  </Grid>
                </Box>
              }
              title="Trainee General Journal"
            />
            <Divider />
            <CardContent>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Date</TableCell>
                      <TableCell>Voucher No</TableCell>
                      <TableCell style={{ width: "30%" }}>
                        Description
                      </TableCell>
                      <TableCell style={{ width: "30%" }}>
                        Acc Head Name
                      </TableCell>
                      <TableCell>Debit</TableCell>
                      <TableCell>Credit</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {loading ? (
                      <TableLoader />
                    ) : journalData.length ? (
                      <>
                        {journalData.map((item) => (
                          <JournalReportTableRow
                            key={item.Id}
                            singleJournalData={item}
                          />
                        ))}
                      </>
                    ) : (
                      <TableNotFound />
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default TraineeJournalReport;
