import React, { useState, useEffect } from "react";
import axios from "axios";
import { Autocomplete, Grid, Button, TextField, Chip } from "@mui/material";
import { Box } from "@mui/system";
import { useForm } from "react-hook-form";
import { IMemberOrganizationProposer } from "src/Types/module/member/UpdateCompanyInfoTypes";
import { url } from "src/Helpers/utils/Constant";
import Toaster from "src/Toaster/Toaster";
import { useAuthContext } from "src/components/Context/AuthContext/AuthContext";
import { ICompanyProposer } from "src/Types/module/member/CompanyInformation";
import { HttpClient } from "src/Helpers/http/http";

interface IProps {
  singleProposer: ICompanyProposer;
  memberId: string;
  setRefresh: (value: boolean) => void;
  setUpdateModal: (value: boolean) => void;
  refresh: boolean;
}

const UpdateCompanyProposer = ({
  singleProposer,
  memberId,
  setRefresh,
  setUpdateModal,
  refresh,
}: IProps) => {
  const { user } = useAuthContext();
  const { handleSubmit } = useForm();
  const [memberOrganizationProposer, setMemberOrganizationProposer] = useState<
    IMemberOrganizationProposer[]
  >([]);
  const [proposerInput, setProposerInput] = useState({
    proposer_request_to: "",
    proposer_seal_signature: "",
    proposer_signature: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [companyName, seTCompanyName] = useState<string>("");
  useEffect(() => {
    axios
      .get(
        `${url}/api/atab/user-member/company-name/get-all/status-or-all/active?company_name=${encodeURIComponent(companyName)}`
      )
      .then(function (res) {
        if (res.data.success) {
          setMemberOrganizationProposer(res.data.data);
        }
      });
  }, [companyName]);

  const proposerOption = memberOrganizationProposer.map((option: any) => ({
    id: option.user_member_id,
    label: `${option.user_member_company_name}`,
  }));

  const onSubmit = async (inputData: any) => {
    setIsLoading(true);

    const formData = new FormData();
    formData.append(
      "proposer_request_id",
      singleProposer.proposer_request_id.toString()
    );
    formData.append("proposer_request_from", memberId.toString());

    for (const key in proposerInput) {
      if (proposerInput[key]) {
        formData.append(key, proposerInput[key]);
      }
    }

    try {
      const data: any = await HttpClient.put(
        `/api/atab/member-company/change/proposer/for-admin`,
        formData
      );

      if (data.success) {
        Toaster().fire({
          icon: "success",
          title: data.message,
        });
        setRefresh(!refresh);
        setUpdateModal(false);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (err: any) {
      Toaster().fire({
        icon: "error",
        title: err?.response?.data.message,
      });
      // setUpdateModal(false);

      setIsLoading(false);
    }
  };
  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)} p={2}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <Box>
            <Autocomplete
              fullWidth
              disablePortal
              defaultValue={{
                id: singleProposer?.proposer_request_to,
                label: singleProposer?.proposer_request_to_company_name,
              }}
              onChange={(e: any, newValue: any) =>
                setProposerInput({
                  ...proposerInput,
                  proposer_request_to: String(newValue?.id),
                })
              }
              isOptionEqualToValue={(proposerOption, value) =>
                proposerOption.id === value.id
              }
              loading={true}
              id="controllable-states-demo"
              options={proposerOption}
              onInputChange={(_event, newInputValue) => {
                seTCompanyName(newInputValue);
              }}
              // sx={{ width: "300px" }}
              getOptionLabel={(option) => option.label}
              renderTags={(value: readonly any[], getTagProps) =>
                value.map((option: any, index: number) => (
                  <Chip
                    variant="filled"
                    label={option}
                    {...getTagProps({ index })}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField
                  required
                  {...params}
                  label="Select proposer name"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: <>{params.InputProps.endAdornment}</>,
                  }}
                />
              )}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            id="outlined-basic"
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
            type="file"
            fullWidth
            label="proposer seal and signature ( max size 5mb )"
            onChange={(e: any) => {
              setProposerInput({
                ...proposerInput,
                proposer_seal_signature: e.target.files[0],
              });
            }}
          />
        </Grid>
      </Grid>
      <Box sx={{ pt: 5, textAlign: "end", mb: 2 }}>
        {isLoading ? (
          <Button
            disabled
            variant="contained"
            disableElevation
            className="add-remove-button"
          >
            loading..
          </Button>
        ) : (
          <Button
            type="submit"
            variant="contained"
            disableElevation
            className="add-remove-button"
          >
            Update
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default UpdateCompanyProposer;
